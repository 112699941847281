<template>
  <div class="church-presentation-template">
    <page-body class>
      <h1>{{ translations.tcAddChurchContact }}</h1>
      <h4>{{ detail.church_name }}</h4>
      <section class="section-3 bg-block mb-4">
        <div>
          <header class="toggle-header mb-3">
            <h2 v-if="active_church_contact_type.index === 0" class="font-style-1">
              {{ translations.tcPastor }}
            </h2>
            <h2 v-if="active_church_contact_type.index === 1" class="font-style-1">
              {{ translations.tcGideonRepresentative }}
            </h2>
            <h2 v-if="active_church_contact_type.index === 2" class="font-style-1">
              {{ translations.tcContactPerson }}
            </h2>
            <ToggleArrow @click="Section1 = !Section1" section="Section1" :status="Section1"></ToggleArrow>
          </header>
          <b-form v-if="active_church_contact_type.index === 1" v-show="Section1">
            <b-row>
              <b-col sm="6" class="font-style-3">
                <div class="form_label">
                  {{ translations.tcSelectRepresentative }}
                </div>
                <b-form-select
                  v-model="selectedContactKey"
                  :options="[...optionsGideonsRep, ...searchedContact]"
                  class="form-control col-left-input g-select flex-0 mr-3"
                >
                </b-form-select>
                <b-button
                  :name="`contactFindButton`"
                  variant="primary"
                  class="flex-1 btn-pl-xsm"
                  @click="showModal($event, 'personSearchModal')"
                >
                  {{ translations.tcFind }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
          <b-form v-if="active_church_contact_type.index !== 1" v-show="Section1" validated>
            <b-row v-if="!this.indixo.ixo_ind_key">
              <b-col sm="6" class="font-style-3">
                <b-form-group class="form-element">
                  <div class="form_label">
                    {{ translations.tcFirstName }}
                  </div>
                  <b-form-input
                    id="pastor-first-name-input"
                    :title="translations.tcFirstName"
                    required
                    class="g-select flex-0 mr-3 mb-3"
                    v-model="indixo.ind_first_name"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="!this.indixo.ixo_ind_key">
              <b-col sm="6" class="font-style-3">
                <b-form-group class="form-element">
                  <div class="form_label">
                    {{ translations.tcLastName }}
                  </div>
                  <b-form-input
                    id="pastor-last-name-input"
                    :title="translations.tcLastName"
                    required
                    class="g-select flex-0 mr-3 mb-3"
                    v-model="indixo.ind_last_name"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="this.indixo.ixo_ind_key">
              <b-col sm="6" class="font-style-3">
                <h2 class="font-style-1">{{ indixo.ind_first_name }} {{ indixo.ind_last_name }}</h2>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="3" class="font-style-3">
                <b-form-checkbox
                  id="checkbox-pastor-primary"
                  v-model="indixo.primary"
                  name="checkbox-pastor-primary"
                  value="true"
                  class="mb-3"
                  unchecked-value="false"
                ></b-form-checkbox
                >&nbsp;
                <label for="checkbox-pastor-primary">
                  <h3 class="font-style-2 check_label">
                    {{ translations.tcPrimary }}
                  </h3>
                </label>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="6" class="font-style-3">
                <b-form-group class="form-element">
                  <div class="form_label">{{ translations.tcTitle }}</div>
                  <b-form-input
                    id="title-input"
                    :title="translations.tcTitle"
                    required
                    class="g-select flex-0 mr-3 mb-3"
                    v-model="indixo.ixo_title"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="6" class="font-style-3">
                <div class="form_label">{{ translations.tcRelationship }}</div>
                <b-form-select
                  :title="translations.tcRelationship"
                  required
                  :options="active_church_contact_type.index === 1 ? repOptions : optionsRelationshipsTranslated"
                  v-model="indixo.ixo_irt_key"
                  class="g-select flex-0 mr-3 mb-3"
                >
                </b-form-select>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </section>
      <section
        v-if="active_church_contact_type.index !== 1 && !this.indixo.ixo_ind_key"
        class="section-3 bg-block mb-4"
      >
        <div class="body">
          <header class="toggle-header mb-3">
            <h2 class="font-style-1">
              {{ translations.tcCommunications }}
            </h2>
            <ToggleArrow @click="Section2 = !Section2" section="Section2" :status="Section2"></ToggleArrow>
          </header>
          <b-form v-show="Section2">
            <b-row>
              <b-col sm="6" class="font-style-3">
                <b-form-group class="form-element">
                  <div class="form_label">{{ translations.tcPhone }}</div>
                  <b-form-input
                    id="phone-input"
                    :title="translations.tcPhone"
                    class="g-select flex-0 mr-3 mb-3"
                    v-model="indixo.phone_number"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="6" class="font-style-3">
                <b-form-group class="form-element">
                  <div class="form_label">{{ translations.tcEmail }}</div>
                  <b-form-input
                    id="email-input"
                    :title="translations.tcEmail"
                    class="g-select flex-0 mr-3 mb-3"
                    v-model="indixo.email"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </section>
      <section
        v-if="active_church_contact_type.index !== 1 && !this.indixo.ixo_ind_key"
        class="section-3 bg-block mb-4"
      >
        <div>
          <header class="toggle-header mb-3">
            <h2 class="font-style-1">
              {{ translations.tcPastorContactSpouse }}
            </h2>
            <ToggleArrow @click="Section3 = !Section3" section="Section3" :status="Section3"></ToggleArrow>
          </header>
          <b-form v-show="Section3">
            <b-row>
              <b-col sm="6" class="font-style-3">
                <b-form-group class="form-element">
                  <div class="form_label">
                    {{ translations.tcFirstName }}
                  </div>
                  <b-form-input
                    id="spouse-first-name-input"
                    :title="translations.tcFirstName"
                    class="g-select flex-0 mr-3 mb-3"
                    v-model="indixo.spouse_first_name"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="6" class="font-style-3">
                <b-form-group class="form-element">
                  <div class="form_label">
                    {{ translations.tcLastName }}
                  </div>
                  <b-form-input
                    id="spouse-last-name-input"
                    :title="translations.tcLastName"
                    class="g-select flex-0 mr-3 mb-3"
                    v-model="indixo.spouse_last_name"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </section>
      <section class="section-5 pt-3 pl-0 pl-sm-4">
        <div class="body">
          <div class="d-flex">
            <b-button variant="primary" @click="handleSaveClick" class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0">
              {{ translations.tcSave }}
            </b-button>
            <b-button variant="tertiary" @click="handleCancelClick" class="flex-0 w-100-sd mb-sm-0">
              {{ translations.tcCancel }}
            </b-button>
          </div>
        </div>
      </section>
    </page-body>
    <PersonSearch @selectionMade="applyFoundPerson($event)"></PersonSearch>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import PersonSearch from '@/components/PersonSearch.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'add-church-contact',
  mixins: [translationMixin],
  props: {
    postAdd: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      translations: {},
      repOptions: [
        {
          text: 'Gideon Representative',
          value: '4ce90b3a-c10d-49d0-b9d7-646cc370f1c8',
        },
      ],
      church: {},
      indixo: {},
      optionsRelationshipsTranslated: {},
      selectedContactKey: null,
      searchedContact: [],
      contactType: null,
      Section1: true,
      Section2: true,
      Section3: true,
    }
  },
  methods: {
    ...mapActions({
      addChurchContact: 'churchMinistry/addChurchContact',
      addChurchGideonRep: 'churchMinistry/addChurchGideonRep',
      getSelectionListsForChurchAdd: 'churchMinistry/getSelectionListsForChurchAdd',
      getElectionGideonMembers: 'election/getElectionGideonMembers',
      loadChurchDetail: 'churchMinistry/loadChurchDetailSelect',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedChurchContact: 'user/setSelectedChurchContact',
    }),
    async handleCancelClick() {
      this.$router.push('/programs/cm/church-contacts')
    },
    async prepareIndividual() {
      this.church = await this.detail
      this.indixo.ixo_irt_key = ['6410898a-1906-4427-b6ca-ce5fe167ade4', '4ce90b3a-c10d-49d0-b9d7-646cc370f1c8', null][
        this.active_church_contact_type.index
      ]
    },
    async prepareData() {
      this.indixo.ixo_org_key = this.userSelectedChurchKey
      this.indixo.ixo_user = this.userId
      this.indixo.cnt_key = this.userCountryObject.key

      switch (this.active_church_contact_type.index) {
        case 0:
          this.contactType = this.translations.tcPastor
          break

        case 1:
          this.contactType = this.translations.tcGideonRepresentative
          this.indixo.ixo_ind_key = this.selectedContactKey
          break

        case 2:
          this.contactType = this.translations.tcContactPerson
          this.indixo.ind_lng_key = this.userLanguageKey
          this.indixo.ind_user = this.userId
          break
      }
    },
    async handleSaveClick() {
      if (this.validateForm()) {
        await Promise.all([this.setLoadingStatus(true), await this.prepareData(), await this.addContact()]).then(() => {
          this.setLoadingStatus(false)
          this.$swal({
            icon: 'success',
            text:
              this.selectedContactKey === null
                ? `${this.contactType} ${this.translations.tcWasAdded}`
                : `${this.contactType} ${this.translations.tcWasSaved}`,
            confirmButtonText: this.translations.tcOk,
          }).then((result) => {
            this.indixo = {}
            this.setSelectedChurchContact({})
            this.$router.push(`/programs/cm/church-contacts`)
          })
        })
      }
    },
    async addContact() {
      switch (this.active_church_contact_type.index) {
        case 1:
          await this.addChurchGideonRep(this.indixo)
          break
        case 0:
        case 2:
          await this.addChurchContact(this.indixo)
          break
      }
    },
    async applyFoundPerson(evt) {
      this.searchedContact.push({ value: evt.ind_key, text: evt.name_text })
      this.selectedContactKey = evt.ind_key
    },
    showModal(e, modalName) {
      this.findButton = !e.target ? e : e.target.name
      this.$root.$emit('bv::show::modal', `${modalName}`)
    },
    validateForm() {
      let rtn = true
      if (this.active_church_contact_type.index === 1) {
        // Gideon Representative
        if (this.selectedContactKey === null) {
          rtn = false
          this.$swal({
            icon: 'error',
            text: this.translations.tcGideonRepRequired,
            confirmButtonText: this.translations.tcOk,
          }).then((result) => {})
        }
      } else {
        // Contact
        if (
          this.indixo.ind_first_name === '' ||
          this.indixo.ind_first_name === null ||
          this.indixo.ind_last_name === '' ||
          this.indixo.ind_last_name === null ||
          this.indixo.ixo_title === '' ||
          this.indixo.ixo_title === null ||
          this.indixo.ixo_irt_key === '' ||
          this.indixo.ixo_irt_key === null
        ) {
          rtn = false
          this.$swal({
            icon: 'error',
            text: this.translations.tcErrorFieldsRequired,
            confirmButtonText: this.translations.tcOk,
          }).then((result) => {})
        }
      }

      return rtn
    },
    async loadContactForEdit() {
      if (!this.$route.path.split('/')[5] === 1) {
        this.indixo = { ...this.empty_individual_ixo }
        return
      } else {
        if (!!this.userSelectedChurchContact && !!this.userSelectedChurchContact.ind_first_name) {
          var pre_indixo = await this.churchContacts.find(
            (ck) => ck.ixo_ind_key == this.userSelectedChurchContact.ind_key
          )
          this.selectedContactKey = this.userSelectedChurchContact.ind_key
          if (
            !!this.presenterList &&
            this.presenterList.filter((pl) => pl.ind_key === this.userSelectedChurchContact.ind_key).length === 0
          ) {
            this.searchedContact = [
              {
                text: `${pre_indixo.ind_first_name} ${pre_indixo.ind_last_name}`,
                value: this.userSelectedChurchContact.ind_key,
              },
            ]
          }
          this.indixo = { ...pre_indixo }
        }
      }
    },
    async page_load() {
      this.indixo = JSON.parse(JSON.stringify(this.empty_individual_ixo))
      await Promise.all([
        this.setLoadingStatus(true),
        await this.getTranslations(),
        await this.getComponentTranslations('common.church-relationship-types'),
        await this.loadChurchDetail(),
        await this.prepareIndividual(),
        await this.getElectionGideonMembers(),
        await this.getSelectionListsForChurchAdd(),
        await this.loadContactForEdit(),
      ]).then(results => {
        this.stripReadableText(results[2])
        const translatedText = {
          common: { ...this.translations.common },
        }
        this.$set(this.translations, 'components', translatedText)
        this.translateDropdownOptions([
          { node: 'church-relationship-types', store: 'optionsRelationships' },
        ])

        this.setLoadingStatus(false)
      })
    },
  },
  async created() {
    await this.page_load()
  },
  computed: {
    ...mapGetters({
      active_church_contact_type: 'churchMinistry/active_church_contact_type',
      churchContacts: 'churchMinistry/churchContacts',
      convertedAddressFormOptions: 'membership/convertedAddressFormOptions',
      detail: 'churchMinistry/churchDetailSelect',
      empty_individual_ixo: 'churchMinistry/empty_individual_ixo',
      gideonMembersList: 'election/gideonMembersList',
      optionsRelationships: 'churchMinistry/optionsRelationships',
      prefCulture: 'user/userPreferredCulture',
      userId: 'user/userId',
      userCountryObject: 'user/userCountryObject',
      userLanguageKey: 'user/userLanguageKey',
      userSelectedChurchKey: 'user/userSelectedChurchKey',
      userSelectedChurchContact: 'user/userSelectedChurchContact',
    }),
    optionsGideonsRep() {
      return [{ value: null, text: this.translations.tcNoRepresentative }, ...this.gideonMembersList.filter(x => x.value.toLowerCase() !== 'unfilled')]
    },
  },
  components: {
    iTooltip: iTooltip,
    pageBody: pageBody,
    PersonSearch: PersonSearch,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

h3 {
  line-height: 1;
}

.church_image {
  background-size: 100% 100%;
}

.check_label {
  position: relative;
  top: -40px;
  left: 30px;
  font-family: 'Open Sans';
}

hr {
  max-width: 671px;
  margin: 25px 0;
  background-color: #979797;
}

.btn.btn-outline,
.btn.btn-outline-primary {
  text-transform: uppercase;
  font-weight: 700;
}

input[type='number'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

.vdp-datepicker div input,
.vue__time-picker input {
  border: 1px solid #636363;
  padding: 10px 20px !important;
  width: 18em;
  font-weight: 600;
  text-transform: uppercase;
}

.vdp-datepicker__calendar {
  font-weight: 600;
  text-transform: uppercase;
}

.vue__time-picker input.display-time {
  padding: 10px 20px !important;
  border: 1px solid #636363;
  width: 17em;
  font-weight: 600;
  height: 3.2em;
}

.bg-block {
  padding: 34px 32px;
  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.change-link {
  display: inline-flex;
  color: #118a0d;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.section-2,
.section-3,
.section-4 {
  padding-top: 22px;
}

.custom-checkbox {
  display: flex;
  height: 1.5rem;
  width: 55px;
  padding: 0;
  @include breakpoint(sm) {
    width: 24px;
  }
  input {
    order: 2;
  }
  label {
    position: relative;
    order: 1;
    height: 1.5rem;
    width: 1.5rem;
    &:before,
    &:after {
      height: 1.5rem;
      width: 1.5rem;
      left: 0;
      top: 0;
    }
  }
}

input[type='checkbox'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

textarea {
  height: 125px;
  padding: 10px 20px !important;
  border-color: #636363 !important;
  color: #003946 !important;
  font-weight: 700 !important;
  line-height: 25px !important;
}

.page-body {
  padding: 80px 15px;
}

.g-row {
  display: flex;
  align-items: center;
  @include breakpoint(sm) {
    flex-wrap: wrap;
  }
  .g-col-1 {
    max-width: 424px;
    width: 100%;
    padding-right: 43px;
    @include breakpoint(sm) {
      max-width: 100%;
      padding-right: 0;
    }
  }
  .g-col-2 {
    max-width: 247px;
    width: 100%;
    @include breakpoint(sm) {
      max-width: 100%;
    }
  }
}

form {
  select.form-control.flex-1 {
    flex: 1 1 auto;
  }
}

.date-select-1 {
  .start-input {
    width: 287px;
    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.time-select-1 {
  .start-time {
    width: 291px;
    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.col-left-input {
  width: 291px !important;
}

.i-tooltip {
  position: relative;
  top: 0px;
  margin-left: 5px;
  line-height: 0;
  vertical-align: text-top;
  svg path {
    fill: #000;
  }
}

.section-5 {
  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }
}

// MOVE GLOBAL
.toggle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
