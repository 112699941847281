<template>
  <b-modal
    class="bmodal"
    size="sm"
    button-size="sm"
    id="personSearchModal"
    ref="modalPersonSearch"
    :title="`${modal_title ? modal_title : translations.tcIndividualSearch}`"
    :cancel-title-html="`<small>${translations.tcCancel}</small>`"
    :ok-title-html="`<small>${translations.tcOk}</small>`"
    centered
    :hide-footer="matchingIndividuals.length > 0"
    @ok="handleOkClick($event)"
    @cancel="handleCancelClick()"
    @show="handleShow()"
    :no-close-on-backdrop="noCloseOnBackdrop"
  >
    <div class="formdiv" v-if="matchingIndividuals.length === 0">
      <b-form>
        <b-row class="lh42">
          <b-col sm="12" class="font-style-3">
            {{ translations.tcMemberNumber }}
            <b-form-input v-model="member_number"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="lh42">
          <b-col sm="12" class="font-style-3">
            {{ translations.tcFirstName }}
            <b-form-input v-model="first_name"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="lh42">
          <b-col sm="12" class="font-style-3">
            {{ translations.tcLastName }}
            <b-form-input v-model="last_name"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="lh42">
          <b-col sm="12" class="font-style-3">
            {{ translations.tcPostalCode }}
            <b-form-input v-model="postal_code"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="lh42">
          <b-col sm="12" class="font-style-3">
            {{ translations.tcState }}
            <b-form-select v-if="!other_country" v-model="selected_state" :options="countryStates"></b-form-select>
            <b-form-input v-model="selected_state" v-else> </b-form-input>
          </b-col>
        </b-row>
        <b-row class="lh42">
          <b-col sm="12" class="font-style-3">
            <b-form-checkbox
              id="country_checkbox"
              v-model="other_country"
              name="country_checkbox"
              :value="true"
              :unchecked-value="false"
              @change="handleCountryCheckboxChange"
            >
              {{ translations.tcOtherCountry || 'Other country' }}
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row class="lh42">
          <b-col sm="12" class="font-style-3">
            {{ translations.tcCountry }}
            <b-form-select v-if="other_country" v-model="selected_country" :options="countriesList"></b-form-select>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <div class="noresults" v-if="showNoResults">
      {{ translations.tcNoMatchesFound }}
    </div>
    <div v-if="matchingIndividuals.length > 0">
      <b-button class="btn-tertiary btn-w-sm float-right" @click="modalBack()">
        {{ translations.tcBack }}
      </b-button>
      <h5>
        {{ translations.tcResults }}
        <br />({{ translations.tcClickToSelect }})
      </h5>
      <div class="long_list">
        <ul>
          <li v-for="people in matchingIndividuals" :key="people.ind_key">
            <div class="resultDiv" @click="handleSearchedPersonClick(people)">
              <b>{{ people.name_text }}</b>
              <div v-if="!other_country">
                <div>{{ people.address_block }}</div>
                <div>{{ people.email_text }}</div>
                <div>{{ people.phone_text }}</div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </b-modal>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'person-search',
  mixins: [translationMixin],
  props: {
    camp_country: {
      type: String,
      default: null
    },
    cmp_org_key: {
      type: String,
      default: null
    },
    modal_title: {
      type: String,
      default: null
    },
    members_only_flag: {
      type: Number,
      default: 1
    },
    not_a_member: {
      type: Number,
      default: 0
    },
    include_inds_without_address: {
      type: Number,
      default: 0
    },
    gender: {
      type: String,
      default: null
    },
    auxiliary_search: {
      type: Boolean,
      default: false
    },
    member_type_key: {
      type: String,
      default: null
    },
    use_org_not_camp: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      camp_country_key: null,
      country_us: 'bc4b70f8-280e-4bb0-b935-9f728c50e183',
      findReturn: null,
      first_name: null,
      gender_list: [
        { value: null, text: 'Any' },
        { value: 'B25E4B70-3F72-48CF-B21E-61A860B36C6A', text: 'Male' },
        { value: 'CBD33F42-B5CA-475E-9E26-F05047365C20', text: 'Female' }
      ],
      last_name: null,
      member_number: null,
      member_only_search: true,
      noCloseOnBackdrop: true,
      other_country: false,
      postal_code: null,
      selected_country: 'bc4b70f8-280e-4bb0-b935-9f728c50e183',
      selected_state: null,
      translations: {}
    }
  },
  computed: {
    ...mapGetters({
      addressFormOptions: 'membership/addressFormOptions',
      countryStates: 'search/countryStateSelect',
      matchingIndividuals: 'search/matchingIndividuals',
      prefCulture: 'user/userPreferredCulture',
      searchCountryKey: 'search/countryKey'
    }),
    showNoResults() {
      return this.matchingIndividuals.length === 0 && this.findReturn
    },
    isUSSelected() {
      return this.camp_country_key === this.country_us
    },
    countriesList() {
      return this.addressFormOptions.countries.map(ct => {
        return { text: ct.display_text, value: ct.value }
      })
    }
  },
  methods: {
    ...mapActions({
      clearSearches: 'search/clearSearches',
      getCountryForCamp: 'search/getCountryForCamp',
      getCountryStates: 'search/getStatesForCountry',
      loadAddressFormOptions: 'membership/loadAddressFormOptions',
      locateIndividuals: 'search/locateIndividuals',
      setLoadingStatus: 'menu/setLoadingStatus'
    }),
    async modalBack() {
      this.findReturn = null
      this.clearSearches()
      await Promise.all([this.getCountryStates(this.camp_country_key)])
    },
    closeModal() {
      this.clearSearches()
      this.$refs.modalPersonSearch.hide()
    },
    handleSearchedPersonClick(ppl) {
      this.findReturn = null
      this.$emit('selectionMade', ppl)
      this.selected_state = null
      this.postal_code = null
      this.member_number = null
      this.last_name = null
      this.first_name = null
      this.findReturn = null
      this.closeModal()
    },
    handleCancelClick() {
      this.selected_state = null
      this.postal_code = null
      this.member_number = null
      this.last_name = null
      this.first_name = null
      this.findReturn = null
    },
    handleCountryCheckboxChange() {
      this.selected_country = this.other_country ? this.selected_country : this.country_us
    },
    async handleShow() {
      await Promise.all(
        [await this.setCountryKey(), this.getCountryStates(this.camp_country_key)],
        await this.loadAddressFormOptions(this.use_org_not_camp ? this.use_org_not_camp : null)
      )
      this.selected_country = this.addressFormOptions.countriesByCamp[0].value // default to first country in list bc it's the one we're using
    },
    async handleOkClick(e) {
      e.preventDefault()
      this.findReturn = null
      // use the input to find a person
      // display the results and allow selection
      // emit the selection
      try {
        this.findReturn = null
        this.setLoadingStatus(true)
        await Promise.all([
          await this.locateIndividuals({
            account_id: this.member_number,
            address_cnt_key: this.selected_country,
            address_postal_code: this.postal_code,
            address_state: this.selected_state,
            first_name: this.first_name,
            gender_key: this.gender,
            last_name: this.last_name,
            members_only_flag: this.members_only_flag,
            not_a_member: this.not_a_member,
            auxiliary_search: this.auxiliary_search,
            member_type_key: this.member_type_key
          })
        ]).then(rtn => (this.findReturn = rtn))
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async setCountryKey() {
      if (!!this.camp_country) {
        this.camp_country_key = this.camp_country
      } else if (!!this.cmp_org_key) {
        await this.getCountryForCamp(this.cmp_org_key)
        this.camp_country_key = this.searchCountryKey
      } else {
        // If neither country key or camp key is passed in, default to US.
        this.camp_country_key = this.country_us
      }
    }
  },

  async created() {
    this.translations = (await this.getComponentTranslations('person-search'))['person-search']
    this.gender_list[1].text = this.translations.tcMale
    this.gender_list[2].text = this.translations.tcFemale
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

.noresults {
  color: #dd0000;
  font-weight: 600;
}

.resultDiv {
  cursor: pointer;
}

.resultDiv:hover {
  background-color: gainsboro;
}

.lh42 {
  margin-bottom: 6px;
}

.long_list {
  margin: 20px 8px;
  max-height: 398px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.long_list ul li {
  font-size: 13px;
  margin-bottom: 10px;
}

.font-style-3 {
  font-weight: 600;
  text-transform: uppercase;
}
</style>
